import React from "react"
import { Helmet } from "react-helmet"

function SEO({ description = '', lang = 'en-EN', title = 'Unibank' }) {

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      description={description || ''}
    />
  )
}

export default SEO
